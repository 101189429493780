import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function MyNavbar() {
  return (
    <div className='navbar'>
    <Navbar expand="lg">
      <Navbar.Brand as={Link} to="/">Gwyneth's Hub</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/" activeClassName="active" className='tab'>Home</Nav.Link>
          <Nav.Link as={Link} to="/temps" activeClassName="active" className='tab'>Temps</Nav.Link>
          <Nav.Link as={Link} to="/stream" activeClassName="active" className='tab'>Snapshot</Nav.Link>
          <Nav.Link as={Link} to="/about" activeClassName="active" className='tab'>About</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </div>
  );
};