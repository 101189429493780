import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import awsconig from "./../aws-exports";
import moment from "moment";
import GaugeChart from "react-gauge-chart";

API.configure(awsconig);

export default function Temps() {
  const chartStyle = {
    width: 225,
    height: 120,
  };

  const dayDisplay = (day) => {
    const i = moment();
    let currentDay = i.format("MM/DD/YYYY");
    if (day === currentDay) {
      return "today";
    } else {
      console.log(currentDay);
      return day;
    }
  };

  const getPercent = (data) => {
    let num = Number(data);
    let percent = num / 100;
    return percent
  };

  const getHumPercent = (data) => {
    let num = Number(data);
    if (num > 0 && num < 20) {
        return 0
    }
    if (num >= 20 && num < 25) {
        return 0.7
    }
    if (num >= 25 && num <= 35) {
        return 0.8
    }
    if (num > 35 && num < 40) {
        return 0.6
    }
    if (num >= 40 && num < 45) {
        return 0.5
    }
    if (num > 45 && num < 50) {
        return 0.3
    }
    else {
        return 0
    }
  }

  const [isLoading, setIsLoading] = useState(false)
  const [sensorOneTemp, setSensorOneTemp] = useState("0");
  const [sensorTwoTemp, setSensorTwoTemp] = useState("0");
  const [sensorOneHum, setSensorOneHum] = useState("0");
  const [sensorTwoHum, setSensorTwoHum] = useState("0");
  const [sensorOneTempPercent, setSensorOneTempPercent] = useState(0);
  const [sensorTwoTempPercent, setSensorTwoTempPercent] = useState("0");
  const [sensorOneHumPercent, setSensorOneHumPercent] = useState("0");
  const [sensorTwoHumPercent, setSensorTwoHumPercent] = useState("0");
  const [time, setTime] = useState();
  const [date, setDate] = useState();

  const getTemps = () => {
    setIsLoading(true);
    API.get("tempsAPI", "/temps", {}).then((res) => {
      let data = res[0]
      setDate(dayDisplay(data.dates));
      setTime(data.times);
      setSensorOneTemp(data.temp1);
      setSensorOneTempPercent(getPercent(data.temp1));
      setSensorTwoTemp(data.temp2);
      setSensorTwoTempPercent(getPercent(data.temp2));
      setSensorOneHum(data.hum1);
      setSensorOneHumPercent(getHumPercent(data.hum1));
      setSensorTwoHum(data.hum2);
      setSensorTwoHumPercent(getHumPercent(data.hum2));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getTemps();
  }, []);
  return (
    <div className="container">
      <h4 className="tempsTitle">Temp Gauges</h4>
      <h3 className="dateTime">
        Data from {date} at {time}
      </h3>
      {isLoading && <div className="loading">working...</div>}
      <div className="gauge">
        <GaugeChart
          id="gauge-chart1"
          className="testGauge"
          style={chartStyle}
          formatTextValue={(value) => sensorOneTemp}
          nrOfLevels={20}
          colors={["#FF0000", "#61FF00"]}
          percent={sensorOneTempPercent}
          animDelay={200}
          animateDuration={1000}
        />
        <p className="bubble">Sensor One Temp</p>
      </div>
      <div className="gauge">
        <GaugeChart
          id="gauge-chart2"
          className="testGauge"
          style={chartStyle}
          formatTextValue={(value) => sensorTwoTemp}
          nrOfLevels={20}
          colors={["#FF0000", "#61FF00"]}
          percent={sensorTwoTempPercent}
          animDelay={200}
          animateDuration={1000}
        />
        <p className="bubble">Sensor Two Temp</p>
      </div>
      <div className="gauge">
        <GaugeChart
          id="gauge-chart3"
          className="testGauge"
          style={chartStyle}
          formatTextValue={(value) => sensorOneHum}
          nrOfLevels={20}
          colors={["#FF0000", "#61FF00"]}
          percent={sensorOneHumPercent}
          animDelay={200}
          animateDuration={1000}
        />
        <p className="bubble">Sensor One Hum</p>
      </div>
      <div className="gauge">
      <GaugeChart
            id="gauge-chart4"
            className="testGauge"
            style={chartStyle}
            formatTextValue={(value) => sensorTwoHum}
            nrOfLevels={20}
            colors={["#FF0000", "#61FF00"]}
            percent={sensorTwoHumPercent}
            animDelay={200}
            animateDuration={1000}
        />
        <p className="bubble">Sensor Two Hum</p>
      </div>
      <div className="tempsBody">
        <p>Above should be the most recent temperature and humidity readings that the raspberry pi has captured, 
            the date and time of the measurments is posted with the data and the gauges represent the ideal ranges for these values.
            Occasionally, I will power down the raspberry pi to make adjustments to the code or add new features.  If the time
            or date values are a few hours or days behind this is likely the reason for it.
        </p>
      </div>
    </div>
  );
}
