import React from "react";
import Gwyneth from './../images/image0.jpeg';
import Proto from './../images/image1.jpeg';
import Software from './../images/image2.jpeg';
import DHT from './../images/dht22.jpeg';
import LCD from './../images/lcd.jpeg';
import Final from './../images/IMG_1583.jpg';
import { Card, CardBody } from "react-bootstrap";

export default function About() {

    return(
        <div className="container">
            <h4 className="aboutTitle">About This Project</h4>
            <Card className="aboutCard">
                <Card.Img variant="top" src={Gwyneth} className="aboutImgCard"/>
                <CardBody className="aboutCardBody">
                    <Card.Title className="aboutCardTitle">How it started</Card.Title>
                    <Card.Text className="aboutCardText">
                        This project began when I first got my gecko, Gwyneth.  I
                        wanted to be able to keep track of her when I was away from
                        home, but the selection of pet monitors was either out
                        of my price range, or did not include the features that
                        I wanted.  So I decided to build my own.    
                    </Card.Text>                    
                </CardBody>
            </Card>
            <Card className="aboutCard">
                <Card.Img variant="top" src={Proto} className="aboutImgCard"/>
                <CardBody className="aboutCardBody">
                    <Card.Title className="aboutCardTitle">Prototyping</Card.Title>
                    <Card.Text className="aboutCardText">
                        I landed on Raspberry Pi for my choice of IOT device to be
                        the brains of this project.  Easy internet connectivity and
                        40 accessible GPIO pins made the hardware side of this project 
                        fairly easy.  Around this time I had also purchased a 3D printer
                        and this allowed me to begin designing custom parts.
                    </Card.Text>
                </CardBody>
            </Card>
            <Card className="aboutCard">
                <Card.Img variant="top" src={Software} className="aboutImgCard"/>
                <CardBody className="aboutCardBody">
                    <Card.Title className="aboutCardTitle">Software</Card.Title>
                    <Card.Text className="aboutCardText">
                        The software portion of this project is relatively simple, only
                        having a few moving parts.  First, using the ADAfruit dht22 library
                        the Pi will read data from inside the enclosure.  Next, a snapshot
                        will be taken of the enclosure using the external camera attachment.
                        Then, all of the physical data will be sent to DynamoDB to be stored,
                        and the captured image will be sent to a public S3 bucket so that it can
                        be accessed by the webpage.  Finally, this very webpage retrieves that 
                        data using a combination of API Gateway and Lambda functions and nicely
                        displays it in a quick and easy to see format.
                    </Card.Text>
                </CardBody>
            </Card>
            <Card className="aboutCard">
                <Card.Img variant="top" src={DHT} className="aboutImgCard"/>
                <Card.Img variant="top" src={LCD} className="aboutImgCard"/>
                <CardBody className="aboutCardBody">
                    <Card.Title className="aboutCardTitle">Hardware</Card.Title>
                    <Card.Text className="aboutCardText">
                        For the hardware portion of this project I decided to go with the previously
                        mentioned dht22 sensors becuase of their ease of use and the supporting 
                        software libraries (no need to reinvent the wheel).  Another critical portion
                        of the hardware includes the camera attachment for the Raspberry Pi, this was
                        another easy choice just for the easy compatibility and large amounts of 
                        support behind it.  The final piece of hardware aside from the Pi itself is the 
                        LCD display screen.  Another very simple and easy to use piece of hardware that
                        makes it so much easier to see conditions at a glance when I am home.
                    </Card.Text>
                </CardBody>
            </Card>
            <Card className="aboutCard">
                <Card.Img variant="top" src={Final} className="aboutImgCard"/>
                <CardBody className="aboutCardBody">
                    <Card.Title className="aboutCardTitle">End Result</Card.Title>
                    <Card.Text className="aboutCardText">
                        In conclusion, this project has been an awesome learning experience into
                        IOT devices and prototyping/design.  It came with a lot of challenges and 
                        obstacles, but it was definitely worth the effort as I can now monitor 
                        my beloved gecko from anywhere in the world.  (Plus it also looks pretty hard.)
                    </Card.Text>
                </CardBody>
            </Card>
        </div>
    )
}