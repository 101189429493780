import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import MyNavbar from "./components/Navbar";
import Footer from "./components/Footer";
import Temps from "./components/Temps";
import Stream from "./components/Stream";
import About from "./components/About";
import 'bootstrap';
import './App.css';
require('dotenv').config()

function App() {
    return <Routes>
        <Route path="/" element={<><MyNavbar /><Home /><Footer /></>}/>
        <Route path="/temps" element={<><MyNavbar /><Temps /><Footer /></>}/>
        <Route path="/stream" element={<><MyNavbar /><Stream /><Footer /></>}/>
        <Route path="/about" element={<><MyNavbar /><About /><Footer /></>}/>
    </Routes>
}

export default App;