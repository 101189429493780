import React, { useState, useEffect } from "react";

export default function Stream() {
    
  const [imageUrl, setImageUrl] = useState('');

//   RETRIEVES IMAGE FROM S3 BUCKET 
  const getPubBucket = () => {
    const url = `https://gecko-snapshot.s3.amazonaws.com/gecko_snapshot.jpg`;
  
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch item from S3. Status: ${response.status}`);
        }
        return response.blob();
      })
      .then(blob => {
        const objectURL = URL.createObjectURL(blob);
        setImageUrl(objectURL)
      })
      .catch(error => {
        console.error('Error fetching item from S3:', error);
      });

    }
    useEffect(() => {
      getPubBucket();
    }, []);
    

    return(
        <div className="container">
            <h4 className="streamTitle">Snapshot</h4>
            {imageUrl && <img src={imageUrl} alt="S3 Image" className="snapshotImg"/>}
            <p className="streamBody">This tab displays a snapshot of the enclosure and is refreshed every 5 minutes.  This
            is accomplished via AWS S3 storage bucket hosting the captured images.  Every five minutes a new image is captured 
            and subsequently displayed here.</p>
        </div>
    )
}