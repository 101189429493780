import React from "react";
import image from "./../images/home_pic.jpg"

export default function Home() {
    return (
    <div className="container">
        <h4 className="homeTitle">Welcome to the homepage!</h4>
        <img className="homeImg" src={image} alt="gecko crawling" />
        <p className="homeBody">This page was created as a quick access hub for me to check on my pet gecko Gwyneth.  
        Assuming that everything is up and running you can view whats happening in the <a href="/stream">snapshot</a> tab.  
        But if not you can always get a good reading on the vitals of her enclosure in the <a href="/temps">temps</a> tab.</p>
    </div>
    );
}